window.Client = {
    init: function() {
        // Do something on initialization
    },

    onRender: function(screenId) {
        // Do something when the flow renders
        const submitButton = document.getElementById('id-20ac1db6');
        const dobInput = document.getElementById('input-2a139924');
        const dobInputBox = document.getElementById('datalist-input-2a139924').nextElementSibling;
        
        const clasStringDob = "block-content input-box input-block inner-narrow with-floating-label floating-label-inline show-error-instantly"

        document.getElementById('input-2a139924-container').className = clasStringDob;
        
        dobInput.addEventListener('blur', function(e) {
            if(dobInput.value && isValidDate(dobInput.value)) {
                submitButton.disabled = false;
                dobInputBox.style.display = 'none';
                dobInputBox.innerText = ""
            }
            else {
                dobInputBox.style.display = 'block';
                dobInputBox.innerText = "Please enter a valid date"
                submitButton.disabled = true;
            }
        });
        
        function isValidDate(dateVal) {
          var currentDate = new Date();
          var getDate = dateVal.split('-');
          var getInputYear = parseInt(getDate[2]) + 120;
        
          var inputDate = new Date('"' + getDate[2] + "-" + getDate[0] + "-" + getDate[1] + '"').setHours(0, 0, 0, 0);
          var toDay = currentDate.setHours(0, 0, 0, 0);
        
          var currentyear = currentDate.getFullYear();
        
          if (inputDate > toDay) {
            return false; //future date
          } else if (currentyear > getInputYear) {
            return false; //past date
          } else {
            return true; //correct date
          }
        }
    }
}